import React from 'react';
import { ReviewList } from '@autovia-uk/polaris-components/components/organisms/ReviewList';

export const getReviewList = (
  title,
  summary,
  overallRating,
  extraClassNames = {},
  headingSize = 3,
  id = '',
) => (
  <ReviewList
    title={title}
    overallRatingLabel=""
    overallRating={overallRating}
    showTextRating={false}
    summary={summary}
    extraClassNames={extraClassNames}
    headingSize={headingSize}
    id={id}
  />
);
